<template>
    <div>
        <v-simple-table class="mt-3 appic-table-light specification-table" dense>
            <template v-slot:default>
                <tr>
                    <th class="grey lighten-3 py-0 pr-2 pl-2 text-left width-1-pct text-start">{{ $t('message.no') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.bundle') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.product') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.spec') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.size') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.quantity') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-2 text-left text-start">{{ $t('message.pricing') }}</th>
                    <th class="grey lighten-3 py-0 pr-2 pl-2 text-left width-1-pct text-start">{{ $t('message.action') }}</th>
                </tr>
                <tr>
                    <td>
                        <div class="d-flex flex-column justify-start">
                            <div class="data-holder"></div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column justify-start">
                            <div class="data-holder"></div>
                        </div>
                    </td>
                    <td>
                        <div class="data-holder"></div>
                        <div class="data-holder"></div>
                        <div class="data-holder"></div>
                        <div class="data-holder"></div>
                    </td>
                    <td>
                        <div class="data-holder"/>
                        <div class="data-holder"/>
                        <div class="data-holder"/>
                        <div class="data-holder"/>
                    </td>
                    <td>
                        <div class="data-holder"/>
                        <div class="data-holder"/>
                        <div class="data-holder"/>
                        <div class="data-holder"/>
                    </td>
                    <td>
                        <div class="d-flex flex-column">
                            <div class="data-holder"/>
                            <div class="data-holder"/>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column">
                            <div class="data-holder"/>
                            <div class="data-holder"/>
                            <div class="data-holder"/>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column">
                            <div class="data-holder"/>
                        </div>
                    </td>
                </tr>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    name: "StockOrderItemList"
}
</script>

<style scoped>
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0.4; }
    100% { opacity:1; }
}
.data-holder {
    -webkit-animation: flickerAnimation 2s infinite;
    -moz-animation: flickerAnimation 2s infinite;
    -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
    height: 24px !important;
    border-radius: 12px;
    background-color: lightgray;
    margin-top: 5px;
}
table td.text-start {
    padding-left: 3px !important;
    padding-right: 0px !important;
}
.v-data-table::v-deep tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
}
.v-data-table::v-deep thead th {
    font-size: 0.875rem !important;
    font-weight: bold !important;
    color: black !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    height: 21px !important;
}
</style>